<template>
    <div id="calendar">
        <div class="card">
            <div class="card-body p-0">
                <div class="calen">
                    <div class="row">
                        <div class="col-12 text-center" style="padding-top:50px !important;">
                            <div class="fs-1 p-4 text-white">25</div>
                        </div>
                        <div class="col-12 text-center pb-4">
                            <div class="fs-5 text-white">Today</div>
                        </div>
                    </div>
                </div>
                <div class="row pt-4 pb-4">
                    <div class="col-6 text-center">
                        <div class="row">
                            <div class="col-12">
                                <font-awesome-icon :icon="['fas', 'database']"/>
                            </div>
                            <div class="col-12">
                                <span>3,000</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 text-center">
                        <div class="row">
                            <div class="col-12">
                                <font-awesome-icon :icon="['fas', 'users']"/>
                            </div>
                            <div class="col-12">
                                <span>50</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default{

}
</script>
<style>
span{
    font-weight: bold;
}
.calen{
    background: #336998;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
     border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}
</style>