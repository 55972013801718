<template>
    <div class="container">
        <newfeed></newfeed>
        <supports></supports>
    </div>
</template>
<script>
import newfeed from '../tipexpress/widgets/newFeed.vue'
import supports from '../tipexpress/widgets/supportWidget.vue'
export default{
    components:{
        newfeed,
        supports,
    }
}
</script>
<style scoped>

</style>