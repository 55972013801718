<template>
    <div class="bg-light">
        <tkiatop></tkiatop>
        <div>
           <main-content></main-content>
        </div>
        <div><br/></div>
    </div>
</template>
<script>
import tkiatop from "../tipexpress/tkiatop.vue"
import mainContent from "../tipexpress/mainContent.vue"
export default {
    components:{
        tkiatop,
        mainContent,
    }
}
</script>