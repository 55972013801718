<template>
    <div class="pb-4 pt-2">
        <div class="row pt-4">
            <div class="col-3">
                <calendar></calendar>
            </div>
            <div class="col-9">
                <div>
                    <font-awesome-icon :icon="['fas', 'newspaper']" style="color:chocolate;" size="lg"/>
                    <span class="p-2 un">News &nbsp;&nbsp;&nbsp;&nbsp;</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import calendar from '../widgets/calendar.vue'
export default{
    components:{
        calendar
    }
}
</script>
<style>
.un{
        font-weight: bold;
        text-decoration: underline;
        text-decoration-line: 2px;
        color: cornflowerblue;
        text-underline-offset: 7px;
        text-decoration-thickness: 2px;
    }
</style>